import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";

const Pricing = (props) => {
  const [pricings, setPricings] = useState([]);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [single, setSingle] = useState({});

  const getPricings = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setPricings(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPricings();
  }, []);

  const addPricing = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      name: e.target.name.value,
      uid: e.target.uid.value,
      maxWeight: e.target.maxWeight.value,
      type: e.target.type.value,
    };

    await axios
      .post(server + "/api/v1/labeltype/create", data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getPricings();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const updatePricing = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      name: single.name,
      uid: single.uid,
      maxWeight: single.maxWeight,
      type: single.type,
      discount: single.discount,
    };

    await axios
      .post(server + "/api/v1/labeltype/update/" + single._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getPricings();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const enableDisableLabelType = async (id) => {
    await axios
      .put(
        server + "/api/v1/labeltype/enableDisableLabelType/" + id,
        {},
        config
      )
      .then((res) => {
        getPricings();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Pricing</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-primary px-2 py-1 btn-sm"
                            data-toggle="modal"
                            data-target="#create"
                          >
                            <i className="bi-plus"></i>
                            Add Label Type
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        {/* table */}
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>MaxWeight</th>
                                <th>UID</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pricings.map((pricing, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{pricing.name}</td>
                                  <td>{pricing.maxWeight}</td>
                                  <td>{pricing.uid}</td>
                                  <td>{pricing.type}</td>
                                  <td>
                                    {pricing.status ? (
                                      <span className="badge bg-soft-success text-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge bg-soft-danger text-danger">
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <Link
                                      to={"/pricing/" + pricing._id}
                                      className="btn btn-sm btn-warning mr-2 px-2 py-1"
                                      title="Manage Pricing"
                                    >
                                      <em className="icon ni ni-eye"></em>
                                    </Link>
                                    <button
                                      className="btn btn-sm btn-primary mr-2 px-2 py-1"
                                      data-toggle="modal"
                                      data-target="#edit"
                                      onClick={() => setSingle(pricing)}
                                      title="Edit"
                                    >
                                      <em className="icon ni ni-edit"></em>
                                    </button>
                                    {pricing.status ? (
                                      <button
                                        className="btn btn-sm btn-danger px-2 py-1"
                                        title="Disable"
                                        onClick={() =>
                                          enableDisableLabelType(pricing._id)
                                        }
                                      >
                                        Disable
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sm btn-success px-2 py-1"
                                        title="Enable"
                                        onClick={() =>
                                          enableDisableLabelType(pricing._id)
                                        }
                                      >
                                        Enable
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="create"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Label Type
              </h5>
              <button
                type="button"
                className=" btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addPricing}>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>UID</label>
                  <input
                    type="text"
                    className="form-control"
                    name="uid"
                    required
                  />
                </div>{" "}
                <div className="form-group mb-3">
                  <label>Type</label>
                  <select name="type" id="" className="form-control">
                    <option value="bbsupply">BBsupply</option>
                    <option value="bulkup3">Bulkup3</option>
                    <option value="labelstore">labelstore</option>
                    <option value="shipd.bz">Shipd.bz</option>
                    <option value="group.pw">Group.pw</option>
                    <option value="wwbiz">wwbiz</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Max Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="maxWeight"
                    required
                  />
                </div>
                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}
      <div
        id="edit"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Edit Label Type
              </h5>
              <button
                type="button"
                className=" btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={updatePricing}>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={single.name}
                    onChange={(e) =>
                      setSingle({ ...single, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group mb-3">
                  <label>UID</label>
                  <input
                    type="text"
                    className="form-control"
                    name="uid"
                    required
                    value={single.uid}
                    onChange={(e) =>
                      setSingle({ ...single, uid: e.target.value })
                    }
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Type</label>
                  <select
                    name="type"
                    id=""
                    className="form-control"
                    value={single.type}
                    onChange={(e) =>
                      setSingle({ ...single, type: e.target.value })
                    }
                  >
                    <option value="bbsupply">BBsupply</option>
                    <option value="bulkup3">Bulkup3</option>
                    <option value="labelstore">labelstore</option>
                    <option value="shipd.bz">Shipd.bz</option>
                    <option value="group.pw">Group.pw</option>
                    <option value="wwbiz">wwbiz</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Max Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="maxWeight"
                    required
                    value={single.maxWeight}
                    onChange={(e) =>
                      setSingle({ ...single, maxWeight: e.target.value })
                    }
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
